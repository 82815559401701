<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link
      href="https://fastinvoice.me"
      class="brand-logo d-flex align-items-center"
    >
      <span>
        <b-img
          :src="appLogoImage"
          alt="logo"
          style="width:80px;"
        />
      </span>
      <h2 class="brand-text text-primary ml-1">
        {{ appName }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">

        <b-alert
          v-if="isConfirmed"
          variant="success"
          show
        >
          <h4 class="alert-heading">
            Your e-mail address has been verified. Redirecting to Fast Invoice in 3 seconds..
          </h4>

        </b-alert>
        <b-alert
          v-if="errorData"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            {{ errorData.message }}
          </h4>
          <div
            v-if="errorData.errors"
            class="alert-body"
          >
            <div
              v-for="(v, k) in errorData.errors"
              :key="k"
            >
              <span
                v-for="error in v"
                :key="error"

                style="display: inline-block;"
              ><strong>*</strong>{{ error }}</span>

            </div>

          </div>
        </b-alert>
        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Go to Fast invoice app
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BImg, BAlert,
} from 'bootstrap-vue'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BAlert,
    BButton,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      isConfirmed: false,
      errorData: null,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    this.confirmEmail()
  },
  methods: {

    confirmEmail() {
      const userData = getUserData()
      if (userData) {
        //   console.log(userData)
        if (userData.email_verified_at != null) {
          this.$router.push({ path: '/' })
        }
      } else {
        // this.$router.push({ path: '/' })
      }
      // eslint-disable-next-line no-empty
      try {

      } catch (error) {
        // this.$router.push({ path: '/' })
      }

      const token = this.$route.query.ptoken

      useJwt
        .confirmEmail({
          token,
        })
        .then(response => {
          const user = this.$user()
          user.email_verified_at = response.data.data.email_verified_at
          localStorage.setItem('userData', JSON.stringify(user))
          this.isConfirmed = true
          this.errorData = null
          setTimeout(() => this.$router.push({ path: '/' }), 3000)
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
